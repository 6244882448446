import { PencilIcon, ShareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { EventModel, getAgeRangeHumanReadable } from "../models/eventModel";
import EventSubscribers from "./EventSubscribers";
import EventSubscribeButton from "./EventSubscribeButton";
import config from "../../../config/config";
import { useEffect, useState } from "react";
import ThreeDotsMenu from "../../../components/ThreeDotsMenu";
import useCachedProfile from "../../../hooks/useCachedProfile";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_EVENT_MUTATION, LIST_EVENTS_QUERY } from "../api/eventService";
import ModalAlert from "../../../components/ModalAlert";
import { SiWhatsapp } from "@icons-pack/react-simple-icons";
import EventProperties from "./EventProperties";
import Modal from "../../../components/ModalDialog";
import { EventOwnerCard } from "./EventOwnerCard";
import { Helmet } from "react-helmet";

const EventCard = ({ event, refetch }: { event: EventModel, refetch?: () => void; }) => {
  const { user } = useCachedProfile();
  const navigate = useNavigate();
  const [deleteEvent] = useMutation(DELETE_EVENT_MUTATION);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const handleDelete = async (eventId: string) => {
    try {
      await deleteEvent({
        variables: { eventId },
        update: (cache) => {
          // Read the existing events from the cache
          const existingEvents: any = cache.readQuery({
            query: LIST_EVENTS_QUERY,
          });

          if (existingEvents && existingEvents.events) {
            // Filter out the deleted event
            const newEvents = existingEvents.events.filter(
              (event: any) => event.id !== eventId
            );

            // Write the new events array back to the cache
            cache.writeQuery({
              query: LIST_EVENTS_QUERY,
              data: { events: newEvents },
            });
          }
        },
      });
      navigate(`/events`);
    } catch (error) {
      console.error("Error removing event:", error);
    }
  };

  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    const updateMapUrl = () => {
      const screenWidth = window.innerWidth;

      const width = screenWidth - Math.round((screenWidth * 50) / 100); // Subtracting 100 for margin
      const height = Math.round((9 / 16) * width); // Calculating height for 16:9 aspect ratio

      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${
        event.location.latitude
      },${
        event.location.longitude
      }&zoom=18&size=${width}x${height}&markers=icon:${encodeURIComponent(
        "http://www.giojoy.com/logo.png"
      )}%7C${event.location.latitude},${event.location.longitude}&key=${
        config.google.mapsApiKey
      }`;
      setMapUrl(url);
    };

    updateMapUrl();
    window.addEventListener("resize", updateMapUrl);

    return () => {
      window.removeEventListener("resize", updateMapUrl);
    };
  }, [event.location.latitude, event.location.longitude]);

  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Hey, dai un'occhiata a questo evento '${event.title}'`,
          url: `${baseUrl}/events/${event.id}`,
        });
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  const [openOwnerInfoDialog, setOpenOwnerInfoDialog] = useState(false);

  return (
    <div className="mx-auto w-full overflow-hidden h-full flex flex-col max-h-130">
      {/* SEO */}
      <Helmet>
        <title>{event?.title || "Evento GioJoy"}</title>
        <meta
          name="description"
          content={event?.description || ""}
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={event?.title || "Evento GioJoy"} />
        <meta property="og:description" content={event?.description || ""} />
        <meta
          property="og:image"
          content={event?.images[0] || "https://www.giojoy.com/logo.png"}
        />
        <meta
          property="og:url"
          content={`https://www.giojoy.com/events/${event?.id}`}
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={event?.title || "Evento GioJoy"} />
        <meta name="twitter:description" content={event?.description || ""} />
        <meta
          name="twitter:image"
          content={event?.images[0] || "https://www.giojoy.com/logo.png"}
        />
      </Helmet>

      <div className="relative">
        {event.owner != null && (
          <div
            className="absolute top-3 left-3 flex flex-row"
            onClick={() => setOpenOwnerInfoDialog(true)}
          >
            <img
              key={event.owner.id}
              src={event.owner.profileImage}
              alt={event.owner.name}
              className="w-12 h-12 rounded-full border-2 border-yellow-200 z-10"
            />

            <Modal
              isOpen={openOwnerInfoDialog}
              onClose={() => setOpenOwnerInfoDialog(false)}
              title={""}
              onCancel={() => setOpenOwnerInfoDialog(false)}
              buttonLabel=""
            >
              <EventOwnerCard owner={event.owner} />
            </Modal>
          </div>
        )}

        {event.owner != null && event.owner.id === user?.userId && (
          <div className="absolute top-3 right-3 flex">
            <ThreeDotsMenu
              title={"Cambia evento"}
              items={[
                {
                  title: "Modifica",
                  icon: (
                    <PencilIcon
                      aria-hidden="true"
                      className="mr-3 h-5 w-5 text-gray-400"
                    />
                  ),
                  onClick: () => {
                    navigate(`/events/${event.id}/edit`);
                  },
                },
                {
                  title: "Elimina",
                  icon: (
                    <XMarkIcon
                      aria-hidden="true"
                      className="mr-3 h-5 w-5 text-gray-400"
                    />
                  ),
                  onClick: async () => {
                    setOpenDeleteAlert(true);
                  },
                },
              ]}
            />
          </div>
        )}

        <img
          src={event.images[0]}
          alt={event.title}
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="px-6 pt-4 pb-2">
        {event.tags.map((tag) => (
          <span
            key={tag}
            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
          >
            {"#" + tag.toLowerCase()}
          </span>
        ))}
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <h4 className="text-md  font-bold text-gray-500">
          {getAgeRangeHumanReadable(event.ageRange)}
        </h4>

        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold text-gray-800">{event.title}</h2>
          <div className="bg-blue-100 rounded-full p-2">
            <ShareIcon
              width={20}
              className="cursor-pointer text-blue-500"
              onClick={handleShare}
            />
          </div>
        </div>
        <p className="mt-3 text-base/7 text-gray-700 flex-grow overflow-auto max-h-30 mb-2">
          {event.description}
          <br />
          <br />
        </p>

        <EventProperties event={event} />

        <a
          href={`https://www.google.com/maps?q=${event.location.latitude},${event.location.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={mapUrl}
            alt="Map preview"
            className="mt-6 rounded-md w-full"
          />
        </a>
        {/* Action items and subscribers */}
        <div className="flex items-center justify-between mt-4">
          <EventSubscribers subscribers={event.subscribers} />
          <EventSubscribeButton event={event} refetch={refetch} />
        </div>

        {/* Disclaimer */}
        <div className="mt-4">
          <p className="text-sm text-gray-500 italic flex items-center">
            Entra in contatto con l'energia di GioJoy,
            <a
              href="https://chat.whatsapp.com/KtugLiPXnQY9fHHtQRc0uA"
              className="inline-flex items-center text-gray-500 hover:text-gray-700 hover:bg-gray-100 px-2 rounded transition-colors duration-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiWhatsapp color="#25D366" className="mr-2" />
              partecipa alla chat
            </a>
          </p>
        </div>
      </div>

      <ModalAlert
        isOpen={openDeleteAlert}
        onClose={() => setOpenDeleteAlert(false)}
        title={`Rimuovere l'evento "${event.title}"?`}
        message={`Sei sicuro di voler cancellare l'evento? L'operazione non è reversibile.`}
        actions={[
          {
            label: "Rimuovi",
            onClick: async () => {
              handleDelete(event.id);
            },
            color: "bg-red-600 hover:bg-red-700",
          },
        ]}
      />
    </div>
  );
};

export default EventCard;
