import { CALENDAR_ROUTE, EVENTS_ROUTE, PROFILE_ROUTE, SIGNOUT_ROUTE } from "../routes";

export const APP_NAME = "GioJoy";

export const publicNavigation = [
    { name: "Eventi", href: EVENTS_ROUTE },
    { name: "Calendario", href: CALENDAR_ROUTE },
    // { name: "Chi siamo", href: "/who" },
  ];
  
  export const userNavigation = [
    { name: "Profilo", href: PROFILE_ROUTE },
    { name: "Sign out", href: SIGNOUT_ROUTE },
  ];