import { APP_NAME } from "../config/consts";

export default function Logo() {
  return (
    <div className="flex flex-shrink-0 items-center">
      <img
        alt="GioJoy Logo"
        src={`${process.env.PUBLIC_URL}/logo.png`}
        className="h-8 w-auto mr-2" 
      />
      <span className="mr-2">{APP_NAME}</span>
    </div>
  );
}
