export default function ErrorPanel({ message }: { message?: string }) {
  return (
    <div className="text-center">
      <h1 className="mt-4 text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
        Mmmm...qualcosa è andato storto
      </h1>
      <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
        {message ||
          "Spiacenti, c'è stato un piccolo problema, riprova più tardi."}
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="/"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Torna alla home
        </a>
      </div>
    </div>
  );
}
