export const ACCESS_TOKEN_KEY = "app_giojoy_accessTokenKey";
export const REFRESH_TOKEN_KEY = "app_giojoy_refreshToken";


export const cities = [
    { name: "Torino", latitude: 45.0703, longitude: 7.6869 },
    { name: "Milano", latitude: 45.4642, longitude: 9.19 },
    { name: "Roma", latitude: 41.9028, longitude: 12.4964 },
    { name: "Napoli", latitude: 40.8518, longitude: 14.2681 },
    { name: "Palermo", latitude: 38.1157, longitude: 13.3615 },
    { name: "Genova", latitude: 44.4056, longitude: 8.9463 },
    { name: "Bologna", latitude: 44.4949, longitude: 11.3426 },
    { name: "Firenze", latitude: 43.7696, longitude: 11.2558 },
    { name: "Bari", latitude: 41.1171, longitude: 16.8719 },
    { name: "Catania", latitude: 37.5079, longitude: 15.083 },
  ];