import { useState, useCallback, ReactNode } from "react";

const usePopup = () => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);

  /**
   * Shows the popup with the provided content.
   * @param content - The ReactNode to display inside the popup.
   */
  const showPopup = useCallback((content: ReactNode) => {
    setContent(content);
    setVisible(true);
  }, []);

  /**
   * Hides the popup.
   */
  const hidePopup = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    setVisible,
    content,
    showPopup,
    hidePopup,
  };
};

export default usePopup;