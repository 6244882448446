export const formatDateToLocale = (dateString: string) => {
    // Parse the date string
    const date = new Date(dateString);
  
    // Format the date according to the current locale
    const formattedDate = new Intl.DateTimeFormat(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  
    return formattedDate;
  };
  
  export const calculateAge = (dateString: string) => {
    const date = new Date(dateString);
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  
  const zodiacSigns = [
    { sign: "Capricorno", start: new Date(0, 0, 1), end: new Date(0, 0, 19) },
    { sign: "Acquario", start: new Date(0, 0, 20), end: new Date(0, 1, 18) },
    { sign: "Pesci", start: new Date(0, 1, 19), end: new Date(0, 2, 20) },
    { sign: "Ariete", start: new Date(0, 2, 21), end: new Date(0, 3, 19) },
    { sign: "Toro", start: new Date(0, 3, 20), end: new Date(0, 4, 20) },
    { sign: "Gemelli", start: new Date(0, 4, 21), end: new Date(0, 5, 20) },
    { sign: "Cancro", start: new Date(0, 5, 21), end: new Date(0, 6, 22) },
    { sign: "Leone", start: new Date(0, 6, 23), end: new Date(0, 7, 22) },
    { sign: "Vergine", start: new Date(0, 7, 23), end: new Date(0, 8, 22) },
    { sign: "Bilancia", start: new Date(0, 8, 23), end: new Date(0, 9, 22) },
    { sign: "Scorpione", start: new Date(0, 9, 23), end: new Date(0, 10, 21) },
    { sign: "Sagittario", start: new Date(0, 10, 22), end: new Date(0, 11, 21) },
    { sign: "Capricorno", start: new Date(0, 11, 22), end: new Date(0, 11, 31) },
  ];
  
  // Function to get the Zodiac sign in Italian
  export function getZodiacSign(date: Date): string {
    const month = date.getMonth();
    const day = date.getDate();
  
    for (const zodiac of zodiacSigns) {
      const startMonth = zodiac.start.getMonth();
      const startDay = zodiac.start.getDate();
      const endMonth = zodiac.end.getMonth();
      const endDay = zodiac.end.getDate();
  
      if (
        (month === startMonth && day >= startDay) ||
        (month === endMonth && day <= endDay) ||
        (month > startMonth && month < endMonth)
      ) {
        return zodiac.sign;
      }
    }
    return "";
  }