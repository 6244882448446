import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import {
  AUTHENTICATE_MUTATION,
  CREATE_USER_MUTATION,
} from "../api/graphql/mutations";
import { jwtDecode } from "jwt-decode";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  registerUser: (
    name: string,
    username: string,
    password: string,
    invitationCode: string
  ) => Promise<void>;
  logout: () => void;
  accessToken: string | null;
}
interface AuthProviderProps {
  children: ReactNode;
  accessTokenKey: string;
  refreshTokenKey: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
// Function to validate the token
const isValidToken = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const decodedToken: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  accessTokenKey,
  refreshTokenKey,
}) => {
  const [authenticate] = useMutation(AUTHENTICATE_MUTATION);
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem(accessTokenKey);
    if (token && token != null) {
      setAuthenticated(isValidToken(token));
      setAccessToken(token);
    }
  }, [accessTokenKey]);

  const [isAuthenticated, setAuthenticated] = useState(() => {
    const token = localStorage.getItem(accessTokenKey);
    return isValidToken(token);
  });

  const login = async (username: string, password: string) => {
    const { data } = await authenticate({
      variables: { username, password },
    });
    setAccessToken(data.authenticate.accessToken);
    const token = data.authenticate.accessToken;

    setAccessToken(token);
    localStorage.setItem(accessTokenKey, token);

    const refreshToken = data.authenticate.refreshToken;
    localStorage.setItem(refreshTokenKey, refreshToken);
    setAuthenticated(true);
  };

  const logout = () => {
    console.log("AuthProvider::logout");
    setAccessToken(null);
    localStorage.removeItem(accessTokenKey);
    setAuthenticated(false);
  };

  const registerUser = async (
    name: string,
    username: string,
    password: string,
    invitationCode: string
  ) => {
    const { data } = await createUser({
      variables: { name, username, password, invitationCode },
    });
    console.log(data);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, registerUser, accessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
