import { gql } from "@apollo/client";
import graphQLClient from "../../../services/gqlClients";
import restClient from "../../../services/restClient";
import { CreateEventInput } from "../models/eventModel";

export const EVENT_FIELDS_FRAGMENT = gql`
  fragment EventFields on Event {
    __typename
    id
    title
    images
    date
    description
    allowKids
    tags
    ageRange {
      __typename
      min
      max
    }
    location {
      __typename
      latitude
      longitude
      name
      description
    }
    price {
      __typename
      amount
      currency
    }
    kidsAgeRange {
      __typename
      min
      max
    }
    subscribers {
      __typename
      id
      name
      profilePicture
    }
    isSubscribed
    owner {
      __typename
      id
      name
      profileImage
    }
  }
`;

export const LIST_EVENTS_QUERY = gql`
  query GetEvents(
    $first: Int!
    $after: String
    $startDate: String!
    $endDate: String!
    $latitude: Float!
    $longitude: Float!
    $radius: Int!
  ) {
    events(
      params: {
        first: $first
        after: $after
        startDate: $startDate
        endDate: $endDate
        position: {
          latitude: $latitude
          radius: $radius
          longitude: $longitude
        }
      }
    ) {
      edges {
        node {
          ...EventFields
        }
        __typename
      }
      __typename
    }
  }
  ${EVENT_FIELDS_FRAGMENT}
`;


export const UNSUBSRIBE_EVENT_MUTATION = gql`
  mutation UnsubscribeEvent($eventId: ID!) {
    unsubscribeEvent(eventId: $eventId)
  }
`;

export const unsubscribeEvent = async (eventId: string): Promise<boolean> => {
  try {
    const { data } = await graphQLClient.mutate({
      mutation: UNSUBSRIBE_EVENT_MUTATION,
      variables: { eventId },
    });

    return data.unsubscribeEvent;
  } catch (error) {
    console.error("Error unsubscribing from event:", error);
    return false;
  }
};

export const SUBSCRIBE_EVENT_MUTATION = gql`
  mutation SubscribeEvent($eventId: ID!, $guests: Int!) {
    subscribeEvent(eventId: $eventId, guests: $guests) {
      needPayment
      success
      stripeClientSecret
      event {
            id
            isSubscribed
            subscribers {
                id
                name
                profilePicture
            }
        }
    }
  }
`;

export const subscribeEvent = async (eventId: string): Promise<boolean> => {
  try {
    const { data } = await graphQLClient.mutate({
      mutation: SUBSCRIBE_EVENT_MUTATION,
      variables: { eventId },
    });

    return data.subscribeEvent;
  } catch (error) {
    console.error("Error subscribing from event:", error);
    return false;
  }
};

const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: EventInput!) {
    createEvent(input: $input)
  }
`;

export const createEvent = async (input: CreateEventInput): Promise<string> => {
  try {
    const { data } = await graphQLClient.mutate({
      mutation: CREATE_EVENT_MUTATION,
      variables: { input },
    });

    return data.createEvent;
  } catch (error) {
    console.error("Error creating event:", error);
    throw new Error("Failed to create event");
  }
};

/**
 * Used to upload Event assets (like images)
 *
 * @param eventId
 * @param file
 */
export const uploadEventFile = async (
  eventId: string,
  file: File
): Promise<string> => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await restClient.post(
      `/api/upload/event/${eventId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status < 200 || response.status >= 300) {
      throw new Error("Failed to upload file");
    }

    const { destinationUrl } = response.data;
    return destinationUrl;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};

export const GET_EVENT_QUERY = gql`
  query Event($id: ID!) {
    event(id: $id) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS_FRAGMENT}
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($eventId: ID!, $input: EventInput!) {
    updateEvent(eventId: $eventId, input: $input)
  }
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId)
  }
`;
