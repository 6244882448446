import { useMutation } from "@apollo/client";
import { ADD_CHILD } from "../api/userService";
import { useState } from "react";
import Modal from "../../../components/ModalDialog";


type Gender = "MALE" | "FEMALE" | "NONE";

interface ChildData {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: Gender;
}

export const AddChildModal = ({
  isOpen,
  onClose,
  refreshProfile,
}: {
  isOpen: boolean;
  onClose: () => void;
  refreshProfile: () => void;
}) => {
  const [addChild] = useMutation(ADD_CHILD);
  const [formData, setFormData] = useState<ChildData>({
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "NONE",
  });
  interface FormErrors {
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    gender?: string;
  }
  
  // Update the useState for errors
  const [errors, setErrors] = useState<FormErrors>({});
  const validate = (): boolean => {
    const newErrors: FormErrors = {};

    // Validate firstName
    if (!formData.firstName.trim()) {
      newErrors.firstName = "Il nome è richiesto.";
    }

    // Validate lastName
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Il cognome è richiesto.";
    }

    // Validate birthDate
    if (!formData.birthDate) {
      newErrors.birthDate = "La data di nascita è richiesta.";
    } else {
      const birthDate = new Date(formData.birthDate);
      if (birthDate.toString() === "Invalid Date") {
        newErrors.birthDate = "Data di nascita non valida.";
      } else if (birthDate > new Date()) {
        newErrors.birthDate = "La data di nascita non può essere nel futuro.";
      }
    }

    // Validate gender - only show error if no selection made
  if (!formData.gender || formData.gender === "NONE") {
    newErrors.gender = "Seleziona un genere.";
  }
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "birthDate") {
      // Convert the date to ISO format for server
      setFormData({ ...formData, [name]: value });

      return;
    }

    setFormData({ ...formData, [name]: value });
  };



  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as Gender;
        
    setFormData(prevData => {
        const newData = {
          ...prevData,
          gender: value
        };
        return newData;
      });
  };;

  const handleSave = async () => {
    if (!validate()) {
      return; // Stop the save operation if validation fails
    }
    try {
      const variables = {
        ...formData,
        birthDate: new Date(formData.birthDate).toISOString(),
      };
      await addChild({ variables });
      refreshProfile();
      onClose();
    } catch (error) {
      console.error("Error adding child:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={async () => {
        await handleSave();
      }}
      onCancel={onClose}
      disabled={false}
      title={"Aggiungi figliə"}
    >
      {/* First Name Field */}
      <input
        type="text"
        name="firstName"
        value={formData.firstName}
        placeholder="Nome"
        className="mt-1  mb-3  block w-full pl-3 pr-10 py-2 text-2xl text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        onChange={handleInputChange}
        required
      />
      {errors.firstName && (
        <p className="mt-1 text-sm text-red-600">{errors.firstName}</p>
      )}

      {/* Last Name Field */}
      <input
        type="text"
        name="lastName"
        value={formData.lastName}
        placeholder="Cognome"
        className="mt-1  mb-3  block w-full pl-3 pr-10 py-2 text-2xl text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        onChange={handleInputChange}
        required
      />
      {errors.lastName && (
        <p className="mt-1 text-sm text-red-600">{errors.lastName}</p>
      )}

      {/* Birth Date Field */}

      <input
        type="date"
        id="birth-date"
        name="birthDate"
        value={formData.birthDate}
        placeholder="Data di nascita"
        className="mt-1 mb-3 block w-full pl-3 pr-10 py-2 text-2xl text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        onChange={handleInputChange}
      />
      {errors.birthDate && (
        <p className="mt-1 text-sm text-red-600">{errors.birthDate}</p>
      )}

      <ul className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div className="flex items-center ps-3">
            <input
              id="gender-male"
              type="radio"
              value="MALE"
              checked={formData.gender === "MALE"}
              name="gender"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              onChange={handleGenderChange}
            />
            <label
              htmlFor="gender-male"
              className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Maschio
            </label>
          </div>
        </li>
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div className="flex items-center ps-3">
            <input
              id="gender-female"
              type="radio"
              value="FEMALE"
              checked={formData.gender === "FEMALE"}
              name="gender"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              onChange={handleGenderChange}
            />
            <label
              htmlFor="gender-female"
              className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Femmina
            </label>
          </div>
        </li>
        <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
          <div className="flex items-center ps-3">
            <input
              id="gender-none"
              type="radio"
              value="NONE"
              checked={formData.gender === "NONE"}
              name="gender"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              onChange={handleGenderChange}
            />
            <label
              htmlFor="gender-none"
              className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Non specificato
            </label>
          </div>
        </li>
      </ul>
  
      {errors.gender && (
        <p className="mt-1 text-sm text-red-600">{errors.gender}</p>
      )}
    </Modal>
  );
};
