import { useMutation } from "@apollo/client";
import formatCurrency from "../../../utility/currencies";
import { EventModel } from "../models/eventModel";
import {
  SUBSCRIBE_EVENT_MUTATION,
  UNSUBSRIBE_EVENT_MUTATION,
} from "../api/eventService";
import { useAuth } from "../../auth/hooks/useAuth";
import { useState } from "react";
import AskToLoginPopup from "../../../components/AskToLoginPopup";
import SpinnerWithLabel from "../../../components/SpinnerWithLabel";
import { CHECKOUT_ROUTE } from "../../../routes";
import EventPurchased from "./EventPurchased";
import { useNavigate } from "react-router-dom";

const EventSubscribeButton = ({
  event,
  refetch,
}: {
  event: EventModel;
  refetch?: () => void;
}) => {
  const navigate = useNavigate();
  const [subscribe, { loading, error }] = useMutation(
    SUBSCRIBE_EVENT_MUTATION,
    {
      onCompleted: () => {
        if (refetch) {
          refetch();
        }
      },
    }
  );
  const [
    unsubscribe,
    { loading: unsubscribeLoading, error: unsubscribeError },
  ] = useMutation(UNSUBSRIBE_EVENT_MUTATION);
  const { isAuthenticated } = useAuth();
  const [isLocalSubscribed, setIsLocalSubscribed] = useState(
    event.isSubscribed
  );
  const [showPopup, setShowPopup] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (error || unsubscribeError) {
    console.error("Error subscribing to event:", error || unsubscribeError);
  }

  if (loading || unsubscribeLoading) {
    return (
      <SpinnerWithLabel
        label={loading ? "Iscrizione ... " : "Cancellazione..."}
      />
    );
  }

  const onSubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isAuthenticated) {
      setShowPopup(true);
      return;
    }
    const res = await subscribe({ variables: { eventId: event.id, guests: 1 } }); // NOTE: guests is hardcoded to 1 but it should be dynamic
    console.log(JSON.stringify(res));
    if (res.data?.subscribeEvent.success) {
      if (res.data?.subscribeEvent.needPayment) {
        navigate(
          `${CHECKOUT_ROUTE}/${event.id}?clientSecret=${res.data.subscribeEvent.stripeClientSecret}`
        );
      }
      setIsDialogOpen(true);
      setIsLocalSubscribed(true);
    } else {
      // Hanlde error
    }
  };

  const onUnsubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await unsubscribe({ variables: { eventId: event.id } });
    if (res.data?.unsubscribeEvent) {
      setIsLocalSubscribed(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {isLocalSubscribed ? (
        <button
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          onClick={onUnsubscribe}
        >
          Cancella iscrizione
        </button>
      ) : event.price.amount === 0 ? (
        <button
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onSubscribe}
        >
          Iscriviti GRATIS!
        </button>
      ) : (
        <button
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onSubscribe}
        >
          {" "}
          Iscriviti per {formatCurrency(event.price.amount, "EUR", "it-IT")}
        </button>
      )}

      {showPopup && <AskToLoginPopup closePopup={closePopup} />}
      <EventPurchased
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        event={event}
      />
    </>
  );
};

export default EventSubscribeButton;
