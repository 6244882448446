import { CalendarIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Modal from "../../../components/ModalDialog";

interface EventDateTimePickerProps {
  onDateChange: (newDate: Date | undefined) => void;
}

const EventDateTimePicker: React.FC<EventDateTimePickerProps> = ({
  onDateChange,
}) => {
  const [selected, setSelected] = useState<Date>();
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [time, setTime] = useState<string>("00:00");

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setTime(newTime);

    if (selected) {
      const [hours, minutes] = newTime.split(":").map(Number);
      const updatedDate = new Date(selected);
      updatedDate.setHours(hours);
      updatedDate.setMinutes(minutes);
      setSelected(updatedDate);
      onDateChange(updatedDate);
    }
  };
  return (
    <div className={`flex flex-col  w-full `}>
      <button
        onClick={() => setIsDateOpen(!isDateOpen)}
        className={`rounded-md w-40 mb-3  ${
          selected ? "bg-green-500" : "bg-indigo-600"
        } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
          selected ? "text-black" : "text-gray-400"
        }`}
      >
        <div className="flex flex-col justify-center items-center">
          <CalendarIcon className="h-7 w-7 mr-2" />
          {selected
            ? selected.toLocaleString(navigator.language, {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "Seleziona una data"}
        </div>
      </button>

      <Modal isOpen={isDateOpen} onClose={() => setIsDateOpen(false)} onCancel={() => setIsDateOpen(false)}  title={"Scegli la data dell'evento"}>
        <div className="felx flex-col justify-between  ">
          <label htmlFor="time" className="text-sm font-semibold mr-3">
            Ora dell'evento
          </label>
          <input
            type="time"
            value={time}
            onChange={handleTimeChange}
            className="border-1 rounded-md w-40 mb-3"
          />

          <DayPicker
            mode="single"
            selected={selected}
            onSelect={(selected: Date | undefined) => {
              onDateChange(selected);
              setSelected(selected);
            }}
            className="flex-grow"
            disabled={{ before: new Date() }}
          />
        </div>
      </Modal>

      
    </div>
  );
};
export default EventDateTimePicker;
