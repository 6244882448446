import { EventModel } from "../models/eventModel";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { MapIcon, UserGroupIcon } from "@heroicons/react/24/outline";

export default function EventProperties({ event }: { event: EventModel }) {
  const formattedDate = new Intl.DateTimeFormat("it-IT", {
    weekday: "long",
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  }).format(new Date(event.date));

  const formattedTime = new Date(event.date).toLocaleTimeString("it-IT", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {event.allowKids && (
          <li key={event.id}>
            <div className="relative pb-4">
              <div className="relative flex space-x-3">
                <div>
                  <span className="bg-green-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                    <UserGroupIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-white"
                    />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      Evento con figli
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {event.kidsAgeRange?.min === 0 &&
                    event.kidsAgeRange?.max === 18
                      ? "tutte le età ammesse"
                      : `età da {event.kidsAgeRange.min} - {event.kidsAgeRange.max} anni`}
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
        <li>
          <div className="relative pb-4">
            <div className="relative flex space-x-3">
              <div>
                <span
                  className="bg-green-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                  <CalendarIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-white"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm font-medium text-gray-900">Quando</p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  <time dateTime={formattedDate}>
                    {formattedDate} alle {formattedTime}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="relative pb-4">
            <div className="relative flex space-x-3">
              <div>
                <span
                  className="bg-blue-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                  <MapIcon aria-hidden="true" className="h-5 w-5 text-white" />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm font-medium text-gray-900">Dove</p>
                </div>
                <div className="max-w-[200px] break-words text-right text-sm text-gray-500">
                  {event.location.description}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
