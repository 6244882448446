import { Navigate } from "react-router-dom";
import { useAuth } from "./features/auth/hooks/useAuth";

export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/signup";
export const LANDING_ROUTE = "/landing";
export const FORGOT_PWD_ROUTE = "/forgot-password";
export const EVENTS_ROUTE = "/events";
export const CALENDAR_ROUTE = "/calendar";
export const CREATE_EVENT_ROUTE = "/events/create";
export const UPDATE_EVENT_ROUTE = "/events/:eventId/edit";
export const WELCOME_ROUTE = "/welcome";
export const CONFIRM_MAIL_ROUTE = "/confirm/:confirmToken";
export const DONATION_ROUTE = "/donations";
export const SIGNOUT_ROUTE = "/signout";
export const PROFILE_ROUTE = "/profile";
export const CHECKOUT_ROUTE = "/checkout";
export const PURCHASED_ROUTE = "/purchased";

interface ProtectedRouteProps {
  component: React.ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Component /> : <Navigate to={LOGIN_ROUTE} />;
};
