import React, { ReactNode, useEffect, useRef } from "react";

interface PopupProps {
  content: ReactNode;
  visible: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const Popup: React.FC<PopupProps> = ({
  content,
  visible,
  onMouseEnter,
  onMouseLeave,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onMouseLeave();
      }
    };

    if (visible) {
      document.addEventListener("keydown", handleEscape);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "auto";
    };
  }, [visible, onMouseLeave]);

  if (!visible) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="dialog"
      aria-modal="true"
    >
      <div
        ref={popupRef}
        className="bg-white border border-gray-300 p-5 rounded-lg shadow-lg max-w-lg w-11/12 max-h-[90vh] overflow-hidden relative"        tabIndex={-1}
        onClick={(e) => e.stopPropagation()} // Prevent click inside from closing
      >
<div className="overflow-y-auto pr-3 max-h-[90vh]">
  {content}
</div>        <button
          onClick={onMouseLeave}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl leading-none"
          aria-label="Close Popup"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Popup;