import React from 'react';

type SubscribersProps = {
    subscribers: {
        id: string;
        name: string;
        profilePicture: string;
    }[];
};

const maxVisibleSubscribers = 3;

const EventSubscribers: React.FC<SubscribersProps> = ({ subscribers }) => {
    const visibleParticipants = subscribers.slice(0, maxVisibleSubscribers);
    const hasMoreParticipants = subscribers.length > maxVisibleSubscribers;

    return (
        <>
            {subscribers.length > 0 ? (
                <>
                    <div className="flex -space-x-2 overflow-hidden mt-2">
                        {visibleParticipants.map((participant) => (
                            <img
                                key={participant.id}
                                src={participant.profilePicture}
                                alt={participant.name}
                                className="w-8 h-8 rounded-full border-2 border-white"
                            />
                        ))}
                        {hasMoreParticipants && (
                            <div className="w-8 h-8 rounded-full border-2 border-white bg-gray-200 flex items-center justify-center text-gray-500">
                                +
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <span className="text-gray-700 font-semibold">Iscriviti per primo!</span>
            )}
        </>
    );
}

export default EventSubscribers;