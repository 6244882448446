import { gql } from "@apollo/client";

export const AUTHENTICATE_MUTATION = gql`
  mutation Authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      accessToken
      refreshToken
      tokenType
      expiresIn
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $name: String!
    $username: String!
    $password: String!
    $invitationCode: String!
  ) {
    createUser(
      username: $username
      password: $password
      name: $name
      invitationCode: $invitationCode
    )
  }
`;

export const MAKE_DONATION_MUTATION = gql`
  mutation MakeDonation($amount: Int!, $paymentInfo: String!) {
    makeDonation(amount: $amount, paymentInfo: $paymentInfo)
  }
`;
