import { CakeIcon, XCircleIcon } from "@heroicons/react/24/outline";

import { calculateAge, formatDateToLocale } from "../../../utility/date";
import { useState } from "react";
import { ChildModel } from "../models/userModel";
import { useMutation } from "@apollo/client";
import {  DELETE_CHILD } from "../api/userService";
import ModalAlert from "../../../components/ModalAlert";
import { AddChildModal } from "./AddChildModal";


export default function ChildrenList({
  isEdit,
  childrenList,
  refreshProfile,
}: {
  isEdit: boolean;
  childrenList: ChildModel[];
  refreshProfile: () => void;
}) {
  const [removeChild] = useMutation(DELETE_CHILD);

  const [isAddChildOpen, setAddChildOpen] = useState(false);
  const [childToDelete, setChildToDelete] = useState<ChildModel | null>(null);

  return (
    <>
      <ul className="w-full divide-y divide-gray-100">
        {childrenList.map((child) => (
          <li
            key={child.childId}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="min-w-0">
              <div className="flex  items-start gap-x-3">
                <p className="text-sm/6 font-semibold text-gray-900">
                  {child.firstName + " " + child.lastName} (
                  {child.gender === "MALE" ? "Maschio" : "Femmina"})
                </p>
              </div>
              <div className="mt-1 flex flex-row items-center gap-x-2 text-xs/5 text-gray-500">
                <CakeIcon className="w-5 mr-4" />{" "}
                {child.birthDate ? formatDateToLocale(child.birthDate) : "N/A"}
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
                <p className="leading-6 ">
                  {child.birthDate
                    ? calculateAge(child.birthDate) + " anni"
                    : "N/A"}
                </p>
              </div>
            </div>
            {isEdit && (
              <div className="flex flex-none items-center gap-x-4">
                <button
                  className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  onClick={() => setChildToDelete(child)}
                >
                  <XCircleIcon className="h-6 w-6 text-red-500 cursor-pointer" />
                </button>
              </div>
            )}
          </li>
        ))}
        {isEdit ? (
          <li>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setAddChildOpen(true)}
            >
              Aggiungi figlio
            </button>
          </li>
        ) : null}
      </ul>

      {/* Single ModalAlert for deletion */}
      {childToDelete && (
        <ModalAlert
          isOpen={!!childToDelete}
          onClose={() => setChildToDelete(null)}
          title="Rimuovere figlio?"
          message={`Sei sicuro di rimuovere ${childToDelete.firstName} dalla lista?`}
          actions={[
            {
              label: "Rimuovi",
              onClick: async () => {
                try {
                  await removeChild({
                    variables: { childId: childToDelete.childId },
                  });
                  refreshProfile();
                  setChildToDelete(null);
                } catch (error) {
                  console.error("Error removing child:", error);
                }
              },
              color: "bg-red-600 hover:bg-red-700",
            },
          ]}
        />
      )}

      <AddChildModal
        isOpen={isAddChildOpen}
        onClose={() => setAddChildOpen(false)}
        refreshProfile={refreshProfile}
      />
    </>
  );
}
