import {
  ArrowRightCircleIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { LOGIN_ROUTE } from "../../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CONFIRM_EMAIL } from "../../user/api/userService";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { useEffect } from "react";

const ConfirmEmail: React.FC = () => {
  const navigate = useNavigate();
  const confirmToken = useParams().confirmToken;
  const [confirmEmail, { loading, error }] = useMutation(CONFIRM_EMAIL, {
    variables: { confirmToken },
    onCompleted: (data) => {
      // Optional: Handle successful confirmation if needed
      console.log("Email confirmed:", data.confirmEmail);
    },
    onError: (error) => {
      // Optional: Handle errors if needed
      console.error("Error confirming email:", error);
    },
  });

  useEffect(() => {
    if (!confirmToken) {
      navigate(LOGIN_ROUTE);
    } else {
      confirmEmail();
    }
  }, [confirmToken, confirmEmail, navigate]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel  message="Non è statoi possibile confermare la tua mail." />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow flex flex-col justify-center items-center text-center">
        <EnvelopeIcon className="h-40 w-40 text-green-300" />
        <h3 className="mt-2 text-5xl font-semibold text-gray-900">
          Hai confermato la mail!
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Ora sei ufficialmente parte della nostra community.
        </p>
        <div className="mt-6">
          <a
            href={LOGIN_ROUTE}
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArrowRightCircleIcon
              aria-hidden="true"
              className="-ml-0.5 mr-1.5 h-5 w-5"
            />
            Accedi
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
