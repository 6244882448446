import { useState } from "react";
import EventCard from "../components/EventCard";
import EventsFilterHeader from "../components/EventsFilterHeader";
import { EventModel } from "../models/eventModel";
import EmptyEventsList from "../components/EmptyEventsList";
import { EventFilters, useFilteredEvents } from "../hooks/useFilteredEvents";
import Loading from "../../../components/Loading";
import { cities } from "../../../consts";
import ErrorPanel from "../../../components/ErrorPanel";

export default function Events() {
  const now = new Date();

  const [filters, setFilters] = useState<EventFilters>({
    month: now.getMonth(),
    year: now.getFullYear(),
    latitude: cities[0].latitude, // For now we are using the first city in the list (Turin)
    longitude: cities[0].longitude,
    radius: 250,
  });

  const { events, loading, error, refetch } = useFilteredEvents(filters);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel message="Riprova più tardi." />;
  }

  return (
      <div className="lg:flex lg:h-full lg:flex-col">
        <EventsFilterHeader filters={filters} onFilterChange={setFilters} />
        <div className="mb-10"> </div>

        {events.length === 0 ? (
          <EmptyEventsList />
        ) : (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {events.map((event: EventModel) => (
              <li
                key={event.id}
                className="col-span-1 bg-white shadow h-full rounded-3xl border border-gray-300 overflow-hidden"
                // onClick={() => navigate(`/events/${event.id}`)}
              >
                <EventCard event={event} refetch={refetch} />
              </li>
            ))}
          </ul>
        )}
      </div>
  );
}
